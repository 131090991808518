<template>
  <div
    id="slide-menu"
    :class="isSlideBarOpen ? 'sidebar-menu open-slide' : 'sidebar-menu'"
  >
    <div class="profile-block" v-if="userInfo && userInfo.name">
      <span class="user-icon">
        <AvtarDefaultIcon />
      </span>
      <div class="user-info">
        <h2>{{ userInfo.name }}</h2>
        <p>+91 {{ userInfo.mobileNo }}</p>
      </div>
      <!-- <span class="icon-btn" @click="navigateTo('/user-profile')">
        <pencil-icon />
      </span> -->
    </div>

    <div class="menu-container" v-if="userInfo && userInfo.name">
      <ul class="side-menu">
        <li>
          <div class="menu-item">
            <span class="icon-btn">
              <OrderIcon />
            </span>
            <span class="text-title" @click="navigateTo('/my-tasks')"
              >My Tasks</span
            >
          </div>
        </li>

        <!-- <li>
          <div class="menu-item">
            <span class="icon-btn">
              <OrderHistoryIcon />
            </span>
            <span class="text-title" @click="router.push('/earning-history')">History</span>
          </div>
        </li> -->

        <li></li>
      </ul>
    </div>
  </div>
</template>
<script>
import AvtarDefaultIcon from "@/components/svgIcons/AvtarDefaultIcon";
import PencilIcon from "@/components/svgIcons/PencilIcon";
import OrderIcon from "@/components/svgIcons/OrderIcon";
// import { mapGetters } from 'vuex';

// import OrderReturnIcon from '@/components/svgIcons/OrderReturnIcon';
// import OrderHistoryIcon from '@/components/svgIcons/OrderHistoryIcon';

export default {
  components: {
    AvtarDefaultIcon,
    PencilIcon,
    OrderIcon,
    // OrderReturnIcon,
    // OrderHistoryIcon,
  },

  props: ["isSlideBarOpen", "userInfo"],

  methods: {
    navigateTo(_path) {
      this.$router.push(_path);
    },
  },

  // computed: {
  //   ...mapGetters({ getUserInfo: "getUserInfo" }),
  // },
};
</script>
<style lang="scss">
@import "@/assets/scss/_sidebar-menu.scss";
</style>
